import jsonata from "jsonata";
import moment from "moment";

export class PortalJsonata {
  private registerFunction(expression: jsonata.Expression) {
    expression.registerFunction(
      "filterKey",
      (arr: string[], keyArray: string[], checkEqual: boolean) => {
        if (!Array.isArray(arr) || arr.length === 0) {
          return [];
        }

        return arr.filter((item: string) => {
          const check = keyArray.some((key) => item.includes(key));
          if (checkEqual) {
            return check;
          }
          return !check;
        });
      }
    );

    expression.registerFunction(
      "calculateSalary",
      (minSalary: string, maxSalary: string, defaultText?: string) => {
        let actualMinSalary = parseInt(minSalary);
        let actualMaxSalary = parseInt(maxSalary);
        if (typeof minSalary === "string") {
          actualMinSalary = parseInt(minSalary.replaceAll(",", ""));
        }
        if (typeof maxSalary === "string") {
          actualMaxSalary = parseInt(maxSalary.replaceAll(",", ""));
        }

        if (Number.isNaN(actualMinSalary)) {
          actualMinSalary = 0;
        }

        if (Number.isNaN(actualMaxSalary)) {
          actualMaxSalary = 0;
        }

        let salaryText = defaultText;

        if (actualMinSalary || actualMaxSalary) {
          salaryText = "";
          if (actualMinSalary) {
            salaryText = `${Math.ceil(actualMinSalary / 1000000)}`;
          }

          if (actualMaxSalary) {
            salaryText += ` - ${Math.ceil(actualMaxSalary / 1000000)}`;
          }

          if (!actualMinSalary && actualMaxSalary) {
            salaryText = salaryText.slice(3);
          }

          salaryText += " triệu";
        }

        return salaryText;
      }
    );

    expression.registerFunction(
      "formatDate",
      (date: any, defaultValue?: string, format?: string) => {
        if (!date) {
          return defaultValue || "";
        }

        const momentObject = moment(date);

        if (!momentObject.isValid) {
          return defaultValue || "";
        }

        return momentObject.format(format || "DD/MM/YYYY");
      }
    );
  }

  async transform(data: any, transformString: string) {
    try {
      const expressionBody = jsonata(transformString);
      this.registerFunction(expressionBody);
      return await expressionBody.evaluate(data);
    } catch (e) {
      console.log("jsonata error", e);
      return null;
    }
  }
}
