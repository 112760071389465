import {
  LayoutConfig,
  LocalStorageKey,
  OfferStatusEnum,
  RecruitmentStageEnum,
  ReturnLayoutData,
  TalentSchedule,
  TalentScheduleStatusEnum,
  defaultNewsTenants,
} from "@/services/constants";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { PortalJsonata } from "./jsonata-functions/portal-jsonata";

var convert = require("color-convert");

const api = () => {
  return process.env.NEXT_PUBLIC_API_URL;
};

export async function getLayout(
  hostNameUrl: string = "",
  clearStorage = true
): Promise<{
  layout: { config: LayoutConfig; headers: any; footers: any; pages: any[] };
  tenant: string;
  articleOption?: { url?: string; query?: string };
} | null> {
  let actualHostName = api() || `https://${hostNameUrl}/api`;

  const getLayoutHelper = (layoutParam: any) => {
    const convertColor = convert.hex.hsl(layoutParam.config.primaryColor);
    return {
      config: {
        ...layoutParam.config,
        hoverColor: `hsl(${convertColor[0]}, ${convertColor[1]}%, ${
          convertColor[2] + 10
        }%)`,
        focusColor: `hsl(${convertColor[0]}, ${convertColor[1]}%, ${
          convertColor[2] - 10
        }%)`,
        boxShadowColor: `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.5)`,
        lightBoxShadowColor: `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.2)`,
        primaryBasedBackground: `hsla(${convertColor[0]}, ${convertColor[1]}%, ${convertColor[2]}%, 0.1)`,
      },
      headers: layoutParam.headers,
      pages: layoutParam.pages,
      footers: layoutParam.footers,
    };
  };

  // return {
  //   layout: getLayoutHelper(layoutTest.layout),
  //   tenant: layoutTest.tenant,
  // };
  const getLayoutApi = axios
    .get(`${actualHostName}/portals/layouts`)
    .catch((error) => null);
  const apiResponse: AxiosResponse<ReturnLayoutData> | null =
    await getLayoutApi;
  if (apiResponse) {
    // console.log("apiResponse", apiResponse);
    const layoutFromApi = apiResponse.data.layout;
    if (
      layoutFromApi &&
      layoutFromApi.config &&
      layoutFromApi.config.primaryColor
    ) {
      const tenant = apiResponse.data.tenant || "";
      if (typeof window !== "undefined") {
        if (clearStorage) window.localStorage.clear();
        window.localStorage.setItem(LocalStorageKey.TENANT, tenant);
        if (layoutFromApi.config.fileUrl) {
          window.localStorage.setItem(
            LocalStorageKey.FILE_URL,
            layoutFromApi.config.fileUrl
          );
          window.localStorage.setItem(
            LocalStorageKey.USE_FILE_URL_CONFIG,
            "true"
          );
        }

        if (layoutFromApi.config.article) {
          const url = layoutFromApi.config.article.url;
          const query = layoutFromApi.config.article.query;

          if (url) {
            window.localStorage.setItem(LocalStorageKey.ARTICLE_URL, url);
          }

          if (query) {
            window.localStorage.setItem(LocalStorageKey.ARTICLE_QUERY, query);
          }
        }
      }

      return {
        layout: getLayoutHelper(layoutFromApi),
        tenant: tenant,
        articleOption: layoutFromApi.config.article,
      };
    }
    return null;
  }
  return null;
}

export async function getArticleById(
  id: string,
  _jsonata: string,
  url: string,
  useDefault?: boolean
) {
  let resLayout = undefined;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  let articleUrl: string | null = localStorage.getItem(
    LocalStorageKey.ARTICLE_URL
  );
  let articleQuery: string | null = localStorage.getItem(
    LocalStorageKey.ARTICLE_QUERY
  );

  if (!tenant) {
    resLayout = await getLayout(url, false);
    if (!resLayout) {
      return [];
    }
    tenant = resLayout.tenant;
    articleUrl = resLayout.articleOption?.url ?? null;
    articleQuery = resLayout.articleOption?.query ?? null;
  }

  const portalJsonata = new PortalJsonata();

  if (useDefault || defaultNewsTenants.includes(tenant)) {
    const data = await axios
      .get(`https://icity.paas.ttgt.vn/ttgt-tphcm/articles/${id}`)
      .catch((error) => null);
    if (!data) {
      return null;
    }
    return await portalJsonata.transform(data.data, _jsonata);
  }

  const dataList: any = await axios
    .post(
      articleUrl ??
        `https://tanlong-stg.talentx.vn/api/portals/articles/graphql`,
      {
        query:
          articleQuery ??
          `query { ehiring_articles { id title author status thumbnail date_created date_updated excerpt content tenant } }`,
      }
    )
    .catch((error) => null);

  if (!dataList) {
    return null;
  }

  if (!dataList?.data) {
    return [];
  }

  if (Array.isArray(dataList.data.ehiring_articles)) {
    const articleData: any[] = dataList.data.ehiring_articles;
    dataList.data.ehiring_articles = articleData.filter(
      (e: any) => e.tenant === tenant
    );
  }

  const transformData = await portalJsonata.transform(dataList.data, _jsonata);

  const data = transformData?.find((dataSingleton: any) => {
    return dataSingleton.id === id;
  });

  return data || null;
}

// Get data dynamic collection
// * url là hostname truyền vào: khi call func từ useEffect thì không lấy được hostname set ở getServerProps nên phải truyền thêm hostname qua props hoặc router
// * collection chỉ định lấy data từ đâu, riêng trường hợp recruitment ở ehiring phải xử lý riêng vì cần mapping location từ masterdata
// * query dynamic
// * jsonata để format lại data theo mẫu, được nhận từ file config
export async function getDataFromCollection(
  url: string,
  collection?: string,
  query?: string,
  _jsonata?: string,
  selectedIds?: string[],
  useDefault?: boolean,
  page: number = 1,
  limit: number = 15
) {
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  let articleUrl: string | null = localStorage.getItem(
    LocalStorageKey.ARTICLE_URL
  );
  let articleQuery: string | null = localStorage.getItem(
    LocalStorageKey.ARTICLE_QUERY
  );

  if (!tenant) {
    const resLayout = await getLayout(url, false);
    if (!resLayout) {
      return [];
    }
    tenant = resLayout.tenant;
    articleUrl = resLayout.articleOption?.url ?? null;
    articleQuery = resLayout.articleOption?.query ?? null;
  }
  if (collection === "/articles") {
    let data;

    if (useDefault || defaultNewsTenants.includes(tenant)) {
      if (query !== "") {
        data = await axios
          .get(`https://icity.paas.ttgt.vn/ttgt-tphcm/articles/${query}`)
          .catch((error) => null);
      } else {
        data = await axios
          .get("https://icity.paas.ttgt.vn/ttgt-tphcm/articles")
          .catch((error) => null);
      }
    } else {
      data = await axios
        .post(
          articleUrl ??
            `https://tanlong-stg.talentx.vn/api/portals/articles/graphql`,
          {
            query:
              articleQuery ??
              "query { ehiring_articles { id title author status thumbnail date_created date_updated excerpt content tenant } }",
          }
        )
        .catch((error) => null);
      data.data.ehiring_articles = data.data.ehiring_articles
        .sort((a: { date_updated: string }, b: { date_updated: string }) => {
          return (
            new Date(b.date_updated).getTime() -
            new Date(a.date_updated).getTime()
          );
        })
        .map((item) => ({ ...item, date_created: item.date_updated }));
    }
    if (!data?.data) {
      return [];
    }

    if (Array.isArray(data.data.ehiring_articles)) {
      const articleData: any[] = data.data.ehiring_articles;
      data.data.ehiring_articles = articleData.filter(
        (e: any) => e.tenant === tenant
      );
    }

    const portalJsonata = new PortalJsonata();
    const returnData = await portalJsonata.transform(
      data.data,
      _jsonata ?? "$"
    );
    if (!returnData) {
      return [];
    }
    if (Array.isArray(returnData)) {
      return returnData.filter((item: any) => {
        if (!selectedIds) {
          return true;
        }
        if (item.id) {
          return selectedIds.includes(item.id);
        }
        if (item._id) {
          return selectedIds.includes(item._id);
        }
        return false;
      });
    }
    return returnData;
  } else {
    if (!collection) {
      return [];
    }

    let actualHostName =
      process.env.NEXT_PUBLIC_API_URL || `https://${url}/api`;
    const listData = await axios
      .get(
        `${actualHostName}/portals/${collection}?${
          query ?? ""
        }&limit=${limit}&page=${page}`
      )
      .catch((error) => null);

    if (!listData) return null;
    const portalJsonata = new PortalJsonata();
    const dataFormatted: any[] = await (Array.isArray(listData.data.data)
      ? portalJsonata.transform(listData.data.data, _jsonata ?? "$")
      : portalJsonata.transform(listData.data, _jsonata ?? "$"));

    const masterdataKeyArray: string[] = [];

    const listDataFormatted = dataFormatted
      ? Array.isArray(dataFormatted)
        ? dataFormatted
        : [dataFormatted]
      : [];

    listDataFormatted.forEach(
      ({ location, tags }: { location: string | string[]; tags: string[] }) => {
        if (
          location &&
          !Array.isArray(location) &&
          masterdataKeyArray.length === 0
        ) {
          masterdataKeyArray.push("site");
        }
        // xử lý tags
        tags.forEach((tag) => {
          if (tag.includes(":")) {
            const tagInfo = tag.split(":");
            // console.log('tagInfo', tagInfo);
            if (tagInfo.length !== 3) {
              return;
            }

            const masterdataKey = tagInfo[1];
            if (!masterdataKeyArray.includes(masterdataKey))
              masterdataKeyArray.push(masterdataKey);
          } else if (!masterdataKeyArray.includes("recruitment-plan-tags")) {
            masterdataKeyArray.push("recruitment-plan-tags");
          }
        });
      }
    );

    const resultMasterdata = await Promise.all(
      masterdataKeyArray.map(async (key) => ({
        key: key,
        data: await getMasterdata(url, key),
      }))
    );

    let masterdataValue: {
      [key: string]: { label: string; value: string; metadata: any }[];
    } = {};
    resultMasterdata.forEach(({ key, data }) => {
      masterdataValue[key] = data;
    });

    const mapOneObject = (inputObject: any) => {
      let returnObject = { ...inputObject };
      const mappedTagArray: { key: string; label: string }[] = [];
      const mapToKeyDict: { [key: string]: string[] } = {};

      returnObject.tags.forEach((tag: string) => {
        let tagLabelFind: string | undefined = undefined;
        let tagKey: string | undefined = undefined;
        let tagValue: string | undefined = undefined;

        if (tag.includes(":")) {
          const tagInfo = tag.split(":");
          if (tagInfo.length !== 3) {
            return;
          }
          tagKey = tagInfo[1] as string;
          tagValue = tagInfo[2];

          if (tag.includes("industry-sector")) {
            const test = masterdataValue[tagKey]?.find(({ value }) => {
              return value === tagValue;
            });
            returnObject.image = test?.metadata[0].img;
            console.log("test", test?.metadata[0].img);
          }
        } else {
          tagKey = "recruitment-plan-tags";
          tagValue = tag;
        }

        if (tagKey && tagValue) {
          tagLabelFind = masterdataValue[tagKey]?.find(({ value }) => {
            return value === tagValue;
          })?.label;

          if (tagLabelFind) {
            if (Array.isArray(returnObject.mapToKey)) {
              const mapToKey = returnObject.mapToKey.find(
                (mapToKey: any) => mapToKey.tagKey === tagKey
              );

              if (mapToKey) {
                if (!mapToKeyDict[mapToKey.key]) {
                  mapToKeyDict[mapToKey.key] = [];
                }

                if (mapToKey.value.includes(tag)) {
                  mapToKeyDict[mapToKey.key].push(tagLabelFind ?? "");
                }
                if (mapToKey.removeFromTags) {
                  return;
                }
              }
            }
            mappedTagArray.push({ key: tagKey, label: tagLabelFind });
          }
        }
      });

      if (Array.isArray(returnObject.mapToKey)) {
        returnObject.mapToKey.forEach(
          (mapToKey: {
            key: string;
            value: string[];
            removeFromTags: boolean;
            defaultValue?: string;
          }) => {
            const valueDict = mapToKeyDict[mapToKey.key];
            if (!valueDict || valueDict.length === 0) {
              returnObject[mapToKey.key] = mapToKey.defaultValue;
            } else {
              returnObject[mapToKey.key] = valueDict.join(", ");
            }
          }
        );
      }
      if (!returnObject.location || returnObject.location === "") {
        returnObject.location =
          masterdataValue["site"]?.find(({ label, value }) => {
            return value === returnObject.location;
          })?.label ?? "";
      }
      // console.log('huyvn mappedTagArray', mappedTagArray)
      const [benefitTags, otherTags] = _.partition(mappedTagArray, {
        key: "benefit",
      });

      if (benefitTags) {
        returnObject.benefits = benefitTags.map((e: any) => e.label);
      }
      returnObject.tags = otherTags.map((e: any) => e.label);
      returnObject.total = listData.data.pageCount;

      return returnObject;
    };

    // console.log('huyvn dataFormatted', {dataFormatted: dataFormatted.map(mapOneObject), query})
    // const updatedArray = dataFormatted.map((item) => ({
    //   ...item,
    //   total: listData.data.pageCount,
    // }));

    // console.log('huyvn updatedArray', updatedArray)

    // return updatedArray
    //   ? Array.isArray(updatedArray)
    //     ? updatedArray.map(mapOneObject)
    //     : mapOneObject(updatedArray)
    //   : [];
    return dataFormatted
      ? Array.isArray(dataFormatted)
        ? dataFormatted.map(mapOneObject)
        : mapOneObject(dataFormatted)
      : [];
  }
}

export async function uploadFile(file: File) {
  let bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return axios.post(
    "https://cf66-2a09-bac5-d41d-18be-00-277-4b.ap.ngrok.io/api/portals/file-manager",
    bodyFormData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

export async function postTalent(url: string, talent: any, _file: File) {
  if (_file) {
    let fileRes;
    fileRes = await uploadFile(_file);
    talent.cv.push(fileRes.data._id);
    return axios
      .post(
        `${
          process.env.NEXT_PUBLIC_API_URL
            ? process.env.NEXT_PUBLIC_API_URL
            : url
        }/portals/talents`,
        talent
      )
      .catch((err) => err.message);
  } else {
    return axios
      .post(
        `${
          process.env.NEXT_PUBLIC_API_URL
            ? process.env.NEXT_PUBLIC_API_URL
            : url
        }/portals/talents`,
        talent
      )
      .catch((err) => err.message);
  }
}

type MasterdataMetaData = Array<{ type: string }>;

interface MasterdataType {
  code: string;
  name: string;
  metadata: MasterdataMetaData;
}

export async function getMasterdata(
  url: string,
  masterdata: string,
  metadataType?: string
): Promise<
  Array<{
    value: string;
    label: string;
    metadata: MasterdataMetaData;
  }>
> {
  const filterMasterdata = (
    masterdataArray: {
      value: string;
      label: string;
      metadata: MasterdataMetaData;
    }[]
  ) => {
    if (!metadataType) {
      return masterdataArray;
    }

    return masterdataArray.filter(({ metadata }) => {
      return metadata.find(
        (metadataSingle) => metadataSingle.type === metadataType
      );
    });
  };

  let actualHostName = process.env.NEXT_PUBLIC_API_URL
    ? process.env.NEXT_PUBLIC_API_URL
    : `https://${url}/api`;

  let actualMasterdata = localStorage.getItem(`masterdata-${masterdata}`);

  if (actualMasterdata) {
    return filterMasterdata(JSON.parse(actualMasterdata));
  }

  let resLayout = undefined;
  let tenant: string | null = localStorage.getItem(LocalStorageKey.TENANT);
  if (!tenant) {
    resLayout = await getLayout(url, false);
    if (!resLayout) {
      return [];
    }
    tenant = resLayout.tenant;
  }
  const qb = RequestQueryBuilder.create();
  if (tenant.length > 0) {
    qb.setFilter({
      field: "tenant",
      operator: "$eq",
      value: tenant,
    });
  }

  const res: AxiosResponse<{ _id: string; data: MasterdataType[] }> | null =
    await axios
      .get(
        `${actualHostName}/portals/masterdata/${masterdata}/data?${qb.query()}`
      )
      .catch((error) => {
        return null;
      });

  if (res) {
    const returnValue = res.data.data.map((e: MasterdataType) => ({
      value: e.code,
      label: e.name,
      metadata: e.metadata,
    }));

    localStorage.setItem(
      `masterdata-${masterdata}`,
      JSON.stringify(returnValue)
    );

    if (tenant.toLowerCase().includes("vietbank")) {
      returnValue.sort((a, b) => {
        const priority = ["HN", "HCM"];

        const aIndex = priority.indexOf(a.value);
        const bIndex = priority.indexOf(b.value);

        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        }
        if (aIndex !== -1) {
          return -1;
        }
        if (bIndex !== -1) {
          return 1;
        }
        return a.label.localeCompare(b.label, "vi");
      });
    }
    return filterMasterdata(returnValue);
  }
  localStorage.setItem(`masterdata-${masterdata}`, JSON.stringify([]));

  return [];
}

export async function getTalentByCode(url: string, code: string) {
  let actualHostName = process.env.NEXT_PUBLIC_API_URL || `https://${url}/api`;
  const data = await axios
    .get(`${actualHostName}/portals/applications/${code}/schedules`)
    .catch((error) => null);
  if (!data) return null;
  const masterdataLocation = await getMasterdata(url, "location");
  const masterdataExaminationMethod = await getMasterdata(
    url,
    "examination-method"
  );
  const masterdataInterviewMethod = await getMasterdata(
    url,
    "interview-method"
  );
  data.data.schedules = mappingArrayObj(
    data.data.schedules,
    masterdataLocation,
    "location"
  );
  const interviewArr = mappingArrayObj(
    data.data.schedules.filter(
      (cond: { scheduleType: string }) => cond.scheduleType == "Interview"
    ),
    masterdataInterviewMethod,
    "scheduleMethod"
  );
  const examinationArr = mappingArrayObj(
    data.data.schedules.filter(
      (cond: { scheduleType: string }) => cond.scheduleType == "Examination"
    ),
    masterdataExaminationMethod,
    "scheduleMethod"
  );
  data.data.schedules = interviewArr.concat(examinationArr);
  data.data.schedules = data.data.schedules.filter(
    (e: any) => e.status == "pending"
  );

  data.data.recruitmentPlan = {
    name: data.data.recruitmentPlan.name,
    stages: data.data.recruitmentPlan.stages.map((ele: any) => {
      const schedules = data.data.schedules.filter(
        (e: any) => e.scheduleType == ele.type
      );
      return { ...ele, schedules };
    }),
  };

  return data.data;
}

// arr1 là mảng data cần mapping
// arr2 là list các phần tử để so sánh mapping
function mappingArrayObj(
  arr1: Array<any>,
  arr2: Array<any>,
  keyMapping: string
) {
  return arr1.map((e, i) => {
    let temp = arr2.find((element) => element.value === e[keyMapping]);
    if (temp) {
      e[keyMapping] = temp.label;
    }
    return e;
  });
}

export async function getTalentByCodeRefactor(
  url: string,
  code: string
): Promise<TalentSchedule | null> {
  let actualHostName = process.env.NEXT_PUBLIC_API_URL || `https://${url}/api`;
  const data: AxiosResponse<TalentSchedule> | null = await axios
    .get(`${actualHostName}/portals/applications/${code}/schedules`)
    .catch((error) => null);
  if (!data) return null;

  const talentScheduleDataReturn: TalentSchedule = { ...data.data };
  const talentScheduleDataCopy: TalentSchedule = { ...data.data };

  const masterDataLocation = await getMasterdata(url, "location");
  const masterDataExaminationMethod = await getMasterdata(
    url,
    "examination-method"
  );
  const masterDataInterviewMethod = await getMasterdata(
    url,
    "interview-method"
  );

  talentScheduleDataReturn.newSchedules =
    talentScheduleDataCopy.newSchedules.map((schedule) => {
      const returnSchedule = { ...schedule };
      const mapScheduleMethodArray =
        schedule.scheduleType === RecruitmentStageEnum.EXAMINATION
          ? [...masterDataExaminationMethod]
          : [...masterDataInterviewMethod];

      const findMapScheduleMethod = mapScheduleMethodArray.find(
        (e) => e.value === schedule.scheduleMethod
      );
      returnSchedule.mappedScheduleMethod = findMapScheduleMethod
        ? findMapScheduleMethod.label
        : "";

      const findMapLocation = masterDataLocation.find(
        (e) => e.value === schedule.location
      );
      returnSchedule.mappedLocation = findMapLocation
        ? findMapLocation.label
        : "";

      return returnSchedule;
    });
  const allRecruitmentStages = [
    ...talentScheduleDataCopy.recruitmentPlan.beginningStages,
    ...talentScheduleDataCopy.recruitmentPlan.stages,
    ...talentScheduleDataCopy.recruitmentPlan.endingStages,
  ];

  talentScheduleDataReturn.mappedRecruitmentPlanStages =
    allRecruitmentStages.map((recruitmentStage, index) => {
      return {
        stage: recruitmentStage,
        schedules: talentScheduleDataReturn.newSchedules.filter((e) => {
          return (
            e.currentStage === index && e.scheduleType === recruitmentStage.type
          );
        }),
      };
    });

  talentScheduleDataReturn.mappedCurrentStage =
    talentScheduleDataCopy.currentStage;
  if (talentScheduleDataCopy.offer) {
    switch (talentScheduleDataCopy.offer.status) {
      case OfferStatusEnum.APPROVED:
        talentScheduleDataReturn.status = TalentScheduleStatusEnum.ACCEPTED;
        talentScheduleDataReturn.mappedOfferStatus = OfferStatusEnum.APPROVED;
        talentScheduleDataReturn.mappedCurrentStage += 1;
        break;
      case OfferStatusEnum.REJECTED:
        talentScheduleDataReturn.status = TalentScheduleStatusEnum.REJECTED;
        talentScheduleDataReturn.mappedOfferStatus = OfferStatusEnum.REJECTED;
        break;

      default:
        talentScheduleDataReturn.mappedOfferStatus =
          OfferStatusEnum.IN_PROGRESS;
        break;
    }
  }
  return talentScheduleDataReturn;
}

export async function getOffer(hostNameUrl: string, code: string) {
  let actualHostName =
    hostNameUrl !== undefined &&
    hostNameUrl !== "" &&
    !hostNameUrl.includes("localhost")
      ? `https://${hostNameUrl}/api`
      : api();
  const uri = `${actualHostName}/portals/applications/${code}/offer`;
  const res = await axios.get(uri).catch((error) => null);
  if (!res) return null;
  return res.data;
}

export async function acceptOffer(hostNameUrl: string, code: string) {
  let actualHostName =
    hostNameUrl !== undefined &&
    hostNameUrl !== "" &&
    !hostNameUrl.includes("localhost")
      ? `https://${hostNameUrl}/api`
      : api();
  const uri = `${actualHostName}/portals/applications/${code}/offer`;
  const res = await axios
    .patch(uri, { status: "accepted" })
    .catch((error) => null);
  if (!res) return null;
  return res.data;
}
export async function rejectOffer(hostNameUrl: string, code: string) {
  let actualHostName =
    hostNameUrl !== undefined &&
    hostNameUrl !== "" &&
    !hostNameUrl.includes("localhost")
      ? `https://${hostNameUrl}/api`
      : api();
  const uri = `${actualHostName}/portals/applications/${code}/offer`;
  const res = await axios
    .patch(uri, { status: "rejected" })
    .catch((error) => null);
  if (!res) return null;
  return res.data;
}
